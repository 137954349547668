<template>
  <div class="auth-wrapper auth-v1 bg-inner-img" v-bind:style="{ backgroundImage: 'url(' + sideImg + ')' }">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
        <b-link class="brand-logo">
          <img :src="logoImg" class="brand-logo-img">
        </b-link>

        <b-card-title class="mb-1">
          Bienvenido a Anclademia 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Inicia sesión en tu cuenta y comienza la aventura.
          
        </b-card-text>
        <!-- form -->
        <ValidationObserver v-slot="{ handleSubmit}">
            <b-form @submit.prevent="handleSubmit(login)" class="auth-login-form mt-2">
            
            <b-alert
             variant="danger"
              show
              dismissible
              v-if="validationErrors"
            >
              <div class="alert-body">
                <span><strong>{{validationErrors.message}}</strong></span>
              </div>
            </b-alert>

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="credentials.email"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  
                />               
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'forgot-password'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <ValidationProvider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="credentials.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
              <!-- <b-form-invalid-feedback id="input-1-live-feedback">Please enter</b-form-invalid-feedback> -->
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
            >
              Iniciar Sesion
            </b-button>
          </b-form>
        </ValidationObserver>

        <b-card-text class="text-center mt-2">
          <span>¿Nuevo en nuestra plataforma? </span>
          <b-link :to="'/register'" >
            <span>Crea una cuenta</span>
          </b-link>
        </b-card-text>
        <!--
        <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div>
        -->
        </b-overlay>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>
<style scoped>
  .bg-inner-img {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .brand-logo-img {
    max-width: 80px;
  }
</style>
<script>
import {
  BButton, BForm, BFormInput, BAlert, BFormGroup, BFormInvalidFeedback,BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BOverlay,
    BFormInvalidFeedback,
    BAlert
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      credentials: {
        email: '',
				password: '',
			},
      status: '',
      // validation rules
      required,
      sideImg: require('@/assets/images/pages/banner-1.jpg'),
      logoImg: require('@/assets/images/pages/anclademia-favi.png'),
      showOverlay: false,
      errors: ''
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    validationErrors(){
      return this.errors.data;
    }
  },
  methods: {
    login() {
      this.showOverlay = true;
      this.$store.dispatch('login', this.credentials).then(() => {
					this.showOverlay = false;
					this.$router.push(this.$store.getters.getRedirectionUrl);			
				}).catch((error) => {
          this.showOverlay = false;
          this.errors = error.response
          if(error.response.data){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
				})
      
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
